var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import AppRoutes from './AppRoutes';
import NavBar from './NavBar';
import './App.scss';
/**
 * This is the app's highest level for introducing custom components
 * @returns {JSX} Application root
 */
function App() {
    return (_jsxs("div", { children: [_jsx(NavBar, {}), _jsx("div", __assign({ className: 'content' }, { children: _jsx(AppRoutes, {}) }))] }));
}
export default App;
