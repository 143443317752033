import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Routes, Route } from 'react-router-dom';
import Home from './Home';
import PageNotFound from './PageNotFound';
/**
* Routes component containing routes for the whole application
* @returns {JSX}
*/
//TODO - figure out how with nginx to route bad paths or true 404's to pagenotfound
var AppRoutes = function (props) { return (_jsxs(Routes, { children: [_jsx(Route, { path: '/', element: _jsx(Home, {}) }), _jsx(Route, { path: '/404', element: _jsx(PageNotFound, {}) }), _jsx(Route, { element: _jsx(PageNotFound, {}) })] })); };
export default AppRoutes;
