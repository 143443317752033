var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import './NavBar.scss';
import { Link } from "react-router-dom";
import Bars from './Bars.svg';
/**
 * Returns the navigation bar component and accompanying menus
 * @returns {JSX} Navigation bar component
 */
function NavBar() {
    // Adds or removes class name of 'reponsive' to navbar when
    // hamburger menu is clicked - .SCSS changes menu layout
    // when 'responsive' is added or removed
    function dropDownMenu() {
        var navElement = document.getElementById('navbar-header');
        if (navElement != null) {
            if (navElement.className === 'navbar') {
                navElement.className += ' responsive';
            }
            else {
                navElement.className = 'navbar';
            }
        }
    }
    return (_jsxs("div", __assign({ className: 'navbar', id: 'navbar-header' }, { children: [_jsx(Link, __assign({ to: '/' }, { children: "Human Theoria" })), _jsx(Link, __assign({ to: '/about' }, { children: "About" })), _jsx(Link, __assign({ to: '/contribute' }, { children: "Contribute" })), _jsx(Bars, { className: 'ham', onClick: dropDownMenu })] })));
}
export default NavBar;
