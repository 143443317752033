import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './Index.scss';
/**
 * This root element, which is rendered in index.html
 * is defined and added to the DOM at this level
 *
 * StrictMode is a development tool for additional checks
 * but does not affect production performance
 *
 * BrowserRouter is the introduction of the React Router component
 * to the application it occurs once here at the highest level
 */
var container = document.getElementById('root');
var root = createRoot(container);
root.render(_jsx(React.StrictMode, { children: _jsx(BrowserRouter, { children: _jsx(App, {}) }) }));
